import { useState } from "react";
import {
    useQuery
} from "@apollo/client";

import {
    Badge,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
    Table,
    Modal,
    Button,
} from 'react-bootstrap';

import {
    Link
} from 'react-router-dom';

import { fuzzyFilterForUnit } from "../lib/filterUtils";

import { UNITS_QUERY } from "../typeDefs";

function ContactsPage() {
    const { loading, error, data } = useQuery(UNITS_QUERY, {pollInterval: 10000});
    const [searchInputValue, setSearchInputValue] = useState("");
    const [megszuntFilterValue, setMegszuntFilterValue] = useState("false");
    const [showModal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [copied, setCopied] = useState(false);

    if (loading) return <p>Betöltés...</p>
    if (error) return <p>Hiba az egységek betöltése közben.</p>

    const emailToContact = {};
    data.egysegek.forEach(egyseg => {
        const email = egyseg.kapcsolattarto.email;
        if (!email) return;

        if (megszuntFilterValue !== "") {
            const value = egyseg.megszunt || false;
            if (value.toString() !== megszuntFilterValue) return;
        }

        if (searchInputValue) {
          const input = searchInputValue.toLowerCase();
          const found = fuzzyFilterForUnit(egyseg, input);
          if (!found) return;
        }

        emailToContact[email] = emailToContact[email] || {
            nev: egyseg.kapcsolattarto.nev,
            email: email,
            egysegek: [],
        };
        emailToContact[email].egysegek.push({
            id: egyseg.id,
            egyseg_nev: egyseg.egyseg_nev,
            tipus: egyseg.egyseg_tipus,
            megszunt: egyseg.megszunt,
        });
    });

    const contactData = Object.values(emailToContact);
    let filteredContactData = contactData;
    const emails = filteredContactData.map(c => c.email);
    const emailsString = emails.join(", ");

    const handleModalShow = () => {
        setShowModal(true);
        setCopied(false);
    }

    return (
        <div className="contacts">
            <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>E-mail címek</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>{emailsString}</div>
                </Modal.Body>
            </Modal>

            <div className="units-search">
                <Form>
                    <Row>
                        <Col>
                            <InputGroup>
                                <InputGroup.Text>Kereső</InputGroup.Text>
                                <FormControl 
                                    value={searchInputValue}
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                    placeholder="Név, email, egység..."
                                />
                            </InputGroup>
                        </Col>

                        <Col md={3}>
                            <InputGroup>
                                <InputGroup.Text>Megszűnt?</InputGroup.Text>
                                <Form.Select value={megszuntFilterValue} onChange={(e) => setMegszuntFilterValue(e.target.value)}>
                                    <option value="">Mindegy</option>
                                    <option value="true">Igen</option>
                                    <option value="false">Nem</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>

                        <Col md={2} style={{"textAlign": "right"}}>
                            <Button onClick={handleModalShow}>E-mail címek</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th colSpan={3}>
                            <span>Találatok: {filteredContactData.length}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>Név</th>
                        <th>Email</th>
                        <th>Egységek</th>
                    </tr>
                </thead>

                <tbody>
                    { filteredContactData.map((c, idx) => (
                        <tr key={idx}>
                            <td>{c.nev}</td>
                            <td>{c.email}</td>
                            <td>
                                { c.egysegek.map((e, eidx) => (
                                    <div key={eidx}>
                                        <Link to={'/' + e.id}>
                                            {e.egyseg_nev}
                                        </Link>
                                        <span style={{"marginLeft": "5px"}}>
                                            ({e.tipus})
                                        </span>
                                        { e.megszunt &&
                                            <Badge className="megszuntBadge" bg="danger" pill>Megszűnt</Badge>
                                        }
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default ContactsPage;