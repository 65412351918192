import { Formik, Form, Field, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';

import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from '../typeDefs';

function ProfilePage() {
  
  const [changePassword, {loading, error}] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      toast.success('Sikeres jelszómódosítás!', {position: 'top-center'});
    },
    onError: () => {
      // ignored
    }
  });

  const validationErrors = error?.graphQLErrors[0]?.extensions?.validationErrors || null;

  const onSubmit = (values) => {
    changePassword({variables: {
      newPassword: values.newPassword
    }});
  };

  const validate = (values) => {
    const errors = {};
    if (!values.newPassword || values.newPassword.length < 8) {
      errors.newPassword = 'Túl rövid jelszó';
    }
    if (!values.newPasswordRepeat || values.newPassword !== values.newPasswordRepeat ) {
      errors.newPasswordRepeat = 'A jelszavak nem egyeznek!';
    }
    return errors;
  };

  return (
    <div style={{"marginTop": "15px"}}>
      <h5>Jelszó módosítása</h5>

      <Formik initialValues={{ newPassword: '', newPasswordRepeat: '' }} onSubmit={onSubmit} validate={validate}>
      {({ isSubmitting, values }) => (
        <Form>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label htmlFor="newPassword" className="col-2 col-form-label" style={{"minWidth": "190px"}}>Új jelszó</label>
                <div className="col">
                  <Field name="newPassword" type="password" className="form-control" />
                  <ErrorMessage name="newPassword" component="div" className="text-danger"/>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="newPasswordRepeat" className="col-2 col-form-label" style={{"minWidth": "190px"}}>
                  Új jelszó megerősítése
                </label>
                <div className="col">
                  <Field name="newPasswordRepeat" type="password" className="form-control" />
                  <ErrorMessage name="newPasswordRepeat" component="div" className="text-danger"/>
                </div>
                
              </div>

              <button type="submit" className="btn btn-primary" disabled={loading}
                      style={{"margin": "15px 0 0 15px", "width": "120px"}}>
                Mentés
              </button>

              { error &&
                <div className="alert alert-danger" style={{"margin": "20px 0 0 10px"}}>
                  <p>{error.message}</p>
                  { validationErrors &&
                    <ul>
                      { validationErrors.map((errorItem, idx) => (
                          <li key={idx}>{errorItem.message}</li>
                        ))
                      }
                    </ul>
                  }
                </div>
              }
            </div>
          </div>
        </Form>
      )}
      </Formik>
    </div>
  );
}

export default ProfilePage;