import {
    Container
} from 'react-bootstrap'

import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import MyNavbar from '../components/MyNavbar';
import UnitDetailPage from './UnitDetailPage';
import UnitsPage from './UnitsPage';
import NewUnitPage from './NewUnitPage';
import ProfilePage from './ProfilePage';
import ContactsPage from './ContactsPage';
import TasksPage from './TasksPage';

function Home({ userChanged }) {
    return (
        <Router basename={process.env.REACT_APP_BASENAME}>
            <MyNavbar userChanged={userChanged}></MyNavbar>

            <Container>
                <Switch>
                    <Route path="/beallitasok">
                        <ProfilePage></ProfilePage>
                    </Route>
                    <Route path="/uj-egyseg">
                        <NewUnitPage></NewUnitPage>
                    </Route>
                    <Route path="/kontaktok">
                        <ContactsPage />
                    </Route>
                    <Route path="/feladatok">
                        <TasksPage />
                    </Route>
                    <Route path="/:id">
                        <UnitDetailPage></UnitDetailPage>
                    </Route>
                    <Route path="/">
                        <UnitsPage></UnitsPage>
                    </Route>
                </Switch>
            </Container>
        </Router>
    );
}

export default Home;