import DatePicker from "react-datepicker";
import moment from 'moment';
import { Prompt, useHistory } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext, useField } from 'formik';
import Autocomplete from "react-autocomplete";
import DeleteUnitButton from './DeleteUnitButton';

import "react-datepicker/dist/react-datepicker.css";
import { DropdownButton } from "react-bootstrap";

function UnitEditorFieldWithCopy({ disabled, inputName, label, value }) {
  const copy = () => {
    try {
      navigator.clipboard.writeText(value);
    } catch (err) {
      // failed to copy...
    }
  }

  return (
    <div className="form-group row">
      <label htmlFor={inputName} className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-9">
        <div className="input-group">
          <Field name={inputName} disabled={disabled} type="text" className="form-control" />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={() => copy()}>
              <i className="bi bi-clipboard"></i>
            </button>
          </div>
        </div>
        <ErrorMessage name={inputName} component="div" className="text-danger" />
      </div>
    </div>
  );
}

function UnitTypeField({ disabled, inputName, label, values, setFieldValue }) {
  return (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-9">
        <Autocomplete
          wrapperStyle={{}}
          inputProps={{"className": "form-control", disabled}}
          getItemValue={item => item.label}
          items={[
            { label: "kereskedelem" },
            { label: "vendéglátás" },
            { label: "üzem" },
            { label: "mozgóbüfé" },
            { label: "főzőkonyha" },
            { label: "tálalókonyha" },
            { label: "állatos egységek" },
            { label: "kistermelő" },
            { label: "közétkeztetés" },
            { label: "egyéb" },
          ]}
          renderItem={(item, isHighlighted) => (
            <div key={item.label} style={{ background: isHighlighted ? "#86b7fe": "white", padding: "5px" }}>
                { item.label }
            </div>
          )}
          menuStyle={{
            zIndex: 99,
            borderRadius: '3px',
            border: "1px solid rgba(0, 0, 0, 0.5)",
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.5)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '50%'
          }}
          value={values[inputName] || ""}
          onChange={e => setFieldValue(inputName, e.target.value)}
          onSelect={value => setFieldValue(inputName, value)}
        />
      </div>
      <ErrorMessage name={inputName} component="div" className="text-danger" />
    </div>
  );
}

function CheckboxField({ disabled, inputName, label }) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{label}</label>
      <div className="col-9">
        <div className="form-check" style={{"marginTop": "6px", "marginBottom": "6px"}}>
          <Field name={inputName} disabled={disabled} type="checkbox" className="form-check-input" />
        </div>
      </div>
    </div>
  );
}

function InspectionPeriodSelect({ inputName, label }) {
  return (
    <div className="form-group row">
      <label className="col-5 col-form-label">
        {label}
      </label>
      <div className="col-7">
        <div className="input-group">
          <Field name={inputName} type="select" className="form-select" as="select">
            <option value="NONE">Nincs megadva</option>
            <option value="MONTHLY">Havi</option>
            <option value="SEASONAL">Szezonális</option>
            <option value="YEARLY">Éves</option>
            <option value="BI_YEARLY">Kétéves</option>
          </Field>
        </div>
      </div>
    </div>
  );
}

function RentalExpiryDateField({ ...props }) {
  const { setFieldValue, values } = useFormikContext();
  const [field] = useField(props);

  return (
    <div className="form-group row">
      <label htmlFor={props.inputName} className="col-sm-3 col-form-label">{props.label}</label>
      <div className="col-sm-9">
        <div className="input-group">
          <DatePicker
            disabled={props.disabled || !values.hatarozott_ideju_szerzodes}
            {...props}
            onBlur={field.onBlur}
            className="form-control"
            dateFormat="yyyy.MM.dd"
            showYearDropdown
            calendarStartDay={1}
            selected={field.value && parseFloat(field.value) || null}
            onChange={(date) => {
              setFieldValue(field.name, date?.getTime() || "")
            }}
          />
        </div>
      </div>
    </div>
  );
}

function InspectionsSection({ ellenorzesek }) {
  return (
    <FieldArray name="ellenorzesek" render={arrayHelpers => (
      <div className="card">

      <div className="card-header mb-2">
        HACCP ellenőrzések
      </div>

      <InspectionPeriodSelect inputName="ellenorzesi_periodus" label="Ellenőrzés periódusa" />

      <div className="card m-2">
        <div className="card-header">
          Ellenőrzések dátumai
        </div>

        <ul className="list-group list-group-flush">
          {ellenorzesek && ellenorzesek.length > 0 &&
            ellenorzesek.map((ellenorzesTs, idx) => (
              <li key={ellenorzesTs} className="list-group-item">
                <div className="row">
                  <div className="col-9">
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy.MM.dd"
                      showYearDropdown
                      calendarStartDay={1}
                      selected={parseFloat(ellenorzesTs)}
                      onChange={(date) => {
                        arrayHelpers.replace(idx, date?.getTime() || null)
                      }}
                    />
                  </div>

                  <div className="col-3" style={{ "textAlign": "right", "padding": "0 2px 0 0" }}>
                    <button type="button" className="btn btn-link" style={{ "padding": "6px 0 6px 0" }}
                      onClick={() => arrayHelpers.remove(idx)}>
                      Törlés
                    </button>
                  </div>
                </div>

              </li>
            ))}

          <li className="list-group-item">
            <button type="button" className="btn btn-link" style={{ "padding": "0 0 0 6px" }}
              onClick={() => arrayHelpers.push(new Date().getTime())}>
              Új dátum hozzáadása
            </button>
          </li>
        </ul>
      </div>
      </div>
    )}>
    </FieldArray>
  );
}

function OtherSection() {
  return (
    <div className="card">
      <div className="card-header">
        Megjegyzések, egyéb
      </div>

      <div className="card-body">
        
        <div className="form-group row">
          <label className="col-md-2 col-form-label">
            Megjegyzések
          </label>
          <div className="col-md-10">
            <div className="input-group">
              <Field name="megjegyzes" className="form-control task-comments mt-2" type="text" as="textarea" rows="4"
                placeholder="Megjegyzések.." />
            </div>
          </div>
        </div>

        <div className="form-check form-check-inline">
          <Field name="slow_payment" type="checkbox" className="form-check-input" />
          <label htmlFor="slow_payment" className="form-check-label">Problémák a fizetéssel</label>
        </div>

        <div className="form-check form-check-inline">
          <Field name="megszunt" type="checkbox" className="form-check-input" />
          <label htmlFor="megszunt" className="form-check-label">Megszűnt egység</label>
        </div>
      </div>
    </div>
  );
}

function UnitEditor({ egyseg, onSubmit }) {

  const history = useHistory();

  const validate = (values) => {
    const errors = {};
    if (!values.egyseg_nev || values.egyseg_nev.length < 1 ) {
      errors.egyseg_nev = 'Egység név nem lehet üres!';
    }
    return errors;
  };

  return (
    <Formik initialValues={{ ...egyseg }} onSubmit={onSubmit} validate={validate}>
    {({ isSubmitting, values, dirty, setFieldValue }) => (
      <Form>
        <div className="card">
          <div className="card-header" style={{"display": "flex"}}>
            <nav style={{"width": "100%"}}>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <button className="btn btn-link"
                    style={{ "padding": "0px", "margin": "0px" }}
                    onClick={() => history.goBack()}
                    type="button">
                      Vissza
                  </button>
                </li>
                <li className="breadcrumb-item mt-auto mb-auto">
                  {egyseg.id}
                </li>
                <li className="breadcrumb-item mt-auto mb-auto">
                  {egyseg.egyseg_nev}
                </li>
              </ol>
            </nav>
            
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}
                    style={{ "width": "150px", "float": "right", "margin": "-5px", "padding": "5px" }}>
              Mentés
            </button>
            
          </div>

          <div className="card-body">
            <Prompt when={dirty} message="Nem mentett módosítások az adatlapon, biztos elnavigálsz?"/>

            <div className="row">
              <div className="col-md-6">
                <h5>Egység adatai</h5>
                <UnitEditorFieldWithCopy inputName="egyseg_nev" label="Név" value={values.egyseg_nev}/>
                <UnitEditorFieldWithCopy inputName="telepules_nev" label="Település" value={values.telepules_nev}/>
                <UnitEditorFieldWithCopy inputName="egyseg_cim" label="Cím" value={values.egyseg_cim}/>

                <h5 className="unit-page-heading">Üzemeltető cég</h5>
                <UnitEditorFieldWithCopy inputName="uzemelteto_ceg.nev" label="Név" value={values.uzemelteto_ceg.nev}/>
                <UnitEditorFieldWithCopy inputName="uzemelteto_ceg.cim" label="Cím" value={values.uzemelteto_ceg.cim}/>
                <UnitEditorFieldWithCopy inputName="uzemelteto_ceg.adoszam" label="Adószám" value={values.uzemelteto_ceg.adoszam}/>
                <UnitEditorFieldWithCopy inputName="uzemelteto_ceg.cegjegyzek_ev_szam" label="Cégjegyzék / EV" value={values.uzemelteto_ceg.cegjegyzek_ev_szam} />
                <UnitEditorFieldWithCopy inputName="uzemelteto_ceg.statisztikai_szam" label="Statisztikai szám" value={values.uzemelteto_ceg.statisztikai_szam} />
              </div>

              <div className="col-md-6">
                <h5>Kapcsolattartók</h5>
                <h6>Elsődleges</h6>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto.nev" label="Név" value={values.kapcsolattarto.nev}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto.telefonszam" label="Telefon" value={values.kapcsolattarto.telefonszam}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto.email" label="Email" value={values.kapcsolattarto.email}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto.beosztas" label="Beosztás" value={values.kapcsolattarto.beosztas}/>
                <h6>Másodlagos</h6>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto2.nev" label="Név" value={values.kapcsolattarto2.nev}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto2.telefonszam" label="Telefon" value={values.kapcsolattarto2.telefonszam}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto2.email" label="Email" value={values.kapcsolattarto2.email}/>
                <UnitEditorFieldWithCopy inputName="kapcsolattarto2.beosztas" label="Beosztás" value={values.kapcsolattarto2.beosztas}/>
              </div>
            </div>

              <hr class="hr" />

            <div className="row">
              <div className="col-md-6">
                  <h5>HACCP adatok</h5>
                  <UnitTypeField inputName="egyseg_tipus" label="Típus" values={values} setFieldValue={setFieldValue} />
                  <UnitEditorFieldWithCopy inputName="tanusitvany_szam" label="Tanúsítvány" value={values.tanusitvany_szam} />
                  <UnitEditorFieldWithCopy inputName="felir_szam" label="FELIR" value={values.felir_szam} />
                  <CheckboxField inputName="amt" label="ÁMT" />

                  <h6>Bérleti szerződés</h6>
                  <CheckboxField inputName="hatarozott_ideju_szerzodes" label="Határozott idejű" />
                  <RentalExpiryDateField name="hatarozott_ideju_szerzodes_lejarat" label="Lejárat" />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col"> 
                  <OtherSection />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                  <InspectionsSection ellenorzesek={values.ellenorzesek} />
              </div>
            </div>
          </div>

          <div className="card-footer">
            <div className="row">
              <div className="col-auto my-auto">
                <div className="row">
                  <div className="col-auto">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ "width": "175px", "margin": "5px 0 5px 0" }}>
                      Mentés
                    </button>
                    
                  </div>
                  <div className="col-auto">
                    <DropdownButton title="Egyéb" variant="outline-secondary" style={{ "margin": "5px 0 5px 0" }}>
                      <DeleteUnitButton id={values.id} />
                    </DropdownButton>
                  </div>
                </div>
              </div>

              <div className="col-lg">
                <div className="editHistoryBlock">
                  <span>Létrehozta: {values.createdBy} @ {moment(parseFloat(values.createdAt)).format("YYYY.MM.DD. HH:mm:ss")}</span>
                  <br />
                  { values.updatedAt &&
                    <span>Utoljára szerkesztette: {values.updatedBy} @ {moment(parseFloat(values.updatedAt)).format("YYYY.MM.DD. HH:mm:ss")}</span>
                  }
                </div>
              </div>

            </div>

          </div>
        </div>
      </Form>
    )}
    </Formik>
  );
}

export default UnitEditor;
