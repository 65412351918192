import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import { useState } from 'react';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  function userChanged() {
    setToken(localStorage.getItem('token'));
  }

  return (
    <div className="App">
      { token
        ? <Home userChanged={userChanged} />
        : <Login userChanged={userChanged} />
      }
    </div>
  );
}

export default App;
