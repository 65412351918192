import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import UnitEditor from "../components/UnitEditor";
import TaskTableForUnit from "../components/TaskTableForUnit";

import {
    SINGLE_UNIT_QUERY,
    UPDATE_UNIT_MUTATION,
} from "../typeDefs";

import toast from 'react-hot-toast';

function UnitDetailPage() {
    const { id } = useParams();
    const { loading, error, data } = useQuery(SINGLE_UNIT_QUERY, {
        variables: {id: parseInt(id)}
    });
    const [updateUnit, {error: saveError }] = useMutation(UPDATE_UNIT_MUTATION, {
        onCompleted: () => {
            toast.success('Sikeres mentés!');
        },
        onError: () => {
            toast.error('Hiba a mentés során!');
        }
    });

    if (loading) return <p>Betöltés...</p>
    if (error) return <p>Hiba az egység betöltése közben.</p>
    if (!data.egyseg) return <p>Nincs ilyen egység!</p>

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        const updating = updateUnit({ variables: {
            updateEgysegId: values.id,
            egyseg: {
                egyseg_nev: values.egyseg_nev,
                egyseg_tipus: values.egyseg_tipus,
                egyseg_cim: values.egyseg_cim,
                telepules_nev: values.telepules_nev,
                uzemelteto_ceg: {
                    nev: values.uzemelteto_ceg?.nev,
                    cim: values.uzemelteto_ceg?.cim,
                    adoszam: values.uzemelteto_ceg?.adoszam,
                    cegjegyzek_ev_szam: values.uzemelteto_ceg?.cegjegyzek_ev_szam,
                    statisztikai_szam: values.uzemelteto_ceg?.statisztikai_szam
                },
                kapcsolattarto: {
                    nev: values.kapcsolattarto?.nev,
                    telefonszam: values.kapcsolattarto?.telefonszam,
                    email: values.kapcsolattarto?.email,
                    beosztas: values.kapcsolattarto?.beosztas
                },
                kapcsolattarto2: {
                    nev: values.kapcsolattarto2?.nev,
                    telefonszam: values.kapcsolattarto2?.telefonszam,
                    email: values.kapcsolattarto2?.email,
                    beosztas: values.kapcsolattarto2?.beosztas
                },
                amt: values.amt,
                megszunt: values.megszunt,
                slow_payment: values.slow_payment,
                ellenorzesek: values.ellenorzesek?.map(e => e.toString()),
                tanusitvany_szam: values.tanusitvany_szam,
                felir_szam: values.felir_szam,
                megjegyzes: values.megjegyzes,
                ellenorzesi_periodus: values.ellenorzesi_periodus,
                hatarozott_ideju_szerzodes: values.hatarozott_ideju_szerzodes,
                hatarozott_ideju_szerzodes_lejarat: values.hatarozott_ideju_szerzodes_lejarat?.toString(),
            }
        } });

        setSubmitting(true);
        updating.then(({ data }) => {
          resetForm({ values: data.updateEgyseg });
        });
    };
    
    return (
        <>
            <UnitEditor egyseg={data.egyseg} onSubmit={onSubmit} />
            { saveError &&
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Hiba történt a mentés során...</h4>
                    <p>HTTP {saveError.networkError.statusCode}</p>
                    <ul>
                        { 
                            saveError.networkError.result.errors.map((error, idx) => (
                                <li key={idx}>{error.message}</li>
                            ))
                        }
                    </ul>
                </div>
            }

            <TaskTableForUnit unitId={id} unitName={data.egyseg.egyseg_nev} ></TaskTableForUnit>
        </>
    );
}

export default UnitDetailPage;