import { useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { useMutation } from "@apollo/client";
import { DELETE_UNIT_MUTATION, UNITS_QUERY } from "../typeDefs";
import toast from 'react-hot-toast';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { Dropdown } from "react-bootstrap";

function DeleteUnitButton({ id }) {
    const history = useHistory();
    const [deleteUnit] = useMutation(DELETE_UNIT_MUTATION, {
        onCompleted: (data) => {
            toast.success(`Sikeres törlés!`);
            history.push(`/`);
        },
        onError: () => {
            toast.error('Hiba a törlés során!');
        },
        refetchQueries: [{query: UNITS_QUERY}]
    });

    const onClick = () => {
        confirmAlert({
            message: 'Biztosan végleg törlöd ezt az egységet?',
            buttons: [{
                label: 'Igen',
                onClick: () => deleteUnit({ variables: {deleteEgysegId: id}})
            }, {
                label: 'Mégsem'
            }]
        });
    };

    return (
        <Dropdown.Item type="button" className="btn btn-outline-secondary" onClick={onClick}>
            Egység törlése
        </Dropdown.Item>
    )
}

export default DeleteUnitButton;