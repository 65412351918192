import { useState, createContext } from "react";

export const UnitsSearchContext = createContext();

export function UnitsSearchContextProvider({ children }) {
    const [searchInputValue, setSearchInputValue] = useState("");
    const [amtFilterValue, setAmtFilterValue] = useState("");
    const [megszuntFilterValue, setMegszuntFilterValue] = useState("false");
    const [sortingValue, setSortingValue] = useState("id-desc");

    const value = {
        searchInput: [searchInputValue, setSearchInputValue],
        amtFilter: [amtFilterValue, setAmtFilterValue],
        megszuntFilter: [megszuntFilterValue, setMegszuntFilterValue],
        sorting: [sortingValue, setSortingValue]
    };

    return (
        <UnitsSearchContext.Provider value={value}>
            { children }
        </UnitsSearchContext.Provider>
    );
}
