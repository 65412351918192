import {
    Container,
    Navbar,
    Nav
} from 'react-bootstrap'

import {
    Link
} from 'react-router-dom';

function MyNavbar({ userChanged }) {
    function logout() {
        localStorage.removeItem('token');
        userChanged();
    }

    return (
        <Navbar bg="dark" variant="dark" expand="md">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                  <Nav className="me-auto">
                      <Nav.Link as={Link} to="/">HACCP Egységek</Nav.Link>
                      <Nav.Link as={Link} to="/feladatok">Feladatok</Nav.Link>
                      <Nav.Link as={Link} to="/kontaktok">Kontaktok</Nav.Link>
                      <Nav.Link as={Link} to="/uj-egyseg">Új egység</Nav.Link>
                  </Nav>
                  <Nav>
                      <Nav.Link as={Link} to="/beallitasok">Beállítások</Nav.Link>
                      <Nav.Link onClick={logout}>Kijelentkezés</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;