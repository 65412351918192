import { useState, createContext } from "react";

export const TasksSearchContext = createContext();

export function TasksSearchContextProvider({ children }) {
  const [searchValue, setSearchValue] = useState("");
  const [assigned, setAssigned] = useState("");
  const [status, setStatus] = useState("NOT_DONE");
  const [sortingValue, setSortingValue] = useState("priorityFirst");


  const value = {
    searchValueState: [searchValue, setSearchValue],
    assignedState: [assigned, setAssigned],
    statusState: [status, setStatus],
    sortingValueState: [sortingValue, setSortingValue],
  };

  return (
    <TasksSearchContext.Provider value={value}>
      {children}
    </TasksSearchContext.Provider>
  );
}
