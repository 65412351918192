import { Badge } from "react-bootstrap";

export default function OldTaskStatusBadge({ status }) {
  switch (status) {
    case 'WORK_NEEDED':
      return <Badge className="megszuntBadge" pill bg="danger">Munka</Badge>;
    case 'REVIEW':
      return <Badge className="megszuntBadge" pill bg="success">Ellenőrzés</Badge>;
    case 'MORE_WORK':
      return <Badge className="megszuntBadge" pill bg="warning">További munka</Badge>;
    default:
      return null;
  }
}