import { Badge } from "react-bootstrap";

export default function PriorityBadge({ priority }) {
  switch (priority) {
    case 'LOW':
      return <Badge className="megszuntBadge" bg="dark">Ráérős</Badge>;
    case 'NORMAL':
      return <Badge className="megszuntBadge" bg="primary">Normál</Badge>;
    case 'HIGH':
      return <Badge className="megszuntBadge" bg="info">SOS!</Badge>;
    default:
      return null;
  }
}