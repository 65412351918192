import {
    useQuery
} from "@apollo/client";

import {
    Badge,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
    Table
} from 'react-bootstrap';

import {
    Link
} from 'react-router-dom';

import { useContext } from 'react';
import moment from 'moment';
import { UNITS_QUERY } from "../typeDefs";
import { UnitsSearchContext } from "../contexts/UnitsSearchContext";
import OldTaskStatusBadge from "../components/OldTaskStatusBadge";
import { fuzzyFilterForUnit } from "../lib/filterUtils";

const getLatestRevisionTs = (timestampArray) => {
    if (!timestampArray ||timestampArray.length === 0) return 0;
    return Math.max.apply(Math, timestampArray);
}

const formatDate = (timestamp) => {
    if (!timestamp) return '';
    return moment(timestamp).format("YYYY.MM.DD");
}

function SOSBadge({ sos }) {
  if (sos) {
    return <Badge className="megszuntBadge" pill bg="info">SOS!</Badge>
  }
  return null;
}

function UnitsPage() {
    const { loading, error, data } = useQuery(UNITS_QUERY, {pollInterval: 10000});

    const {searchInput, amtFilter, megszuntFilter, sorting } = useContext(UnitsSearchContext);

    const [searchInputValue, setSearchInputValue] = searchInput;
    const [amtFilterValue, setAmtFilterValue] = amtFilter;
    const [megszuntFilterValue, setMegszuntFilterValue] = megszuntFilter;
    const [sortingValue, setSortingValue] = sorting;

    if (loading) return <p>Betöltés...</p>
    if (error) return <p>Hiba az egységek betöltése közben.</p>

    const extendedData = data.egysegek.map(egyseg => {
        return {
            ...egyseg,
            utolso_ellenorzes: getLatestRevisionTs(egyseg.ellenorzesek)
        }
    });
    let filteredTable = extendedData;


    if (searchInputValue) {
        const input = searchInputValue.toLowerCase();
        filteredTable = filteredTable.filter(e => fuzzyFilterForUnit(e, input));
    }

    if (amtFilterValue !== "") {
        filteredTable = filteredTable.filter(e => {
            const value = e.amt || false;
            return value.toString() === amtFilterValue;
        });
    }

    if (megszuntFilterValue !== "") {
      filteredTable = filteredTable.filter(e => {
          const value = e.megszunt || false;
          return value.toString() === megszuntFilterValue;
      });
    }

    // handle sorting
    let sortingFn;
    switch(sortingValue) {
        case "id-desc":
            sortingFn = (e1, e2) => e2.id - e1.id;
            break;
        case "id-asc":
            sortingFn = (e1, e2) => e1.id - e2.id;
            break;
        case "last-rev-asc":
            sortingFn = (e1, e2) => e1.utolso_ellenorzes - e2.utolso_ellenorzes;
            break;
        case "last-rev-desc":
            sortingFn = (e1, e2) => e2.utolso_ellenorzes - e1.utolso_ellenorzes;
            break;
        case "type-asc":
            sortingFn = (e1, e2) => e1.egyseg_tipus.localeCompare(e2.egyseg_tipus);
            break;
        default:
            sortingFn = (e1, e2) => e1.id - e2.id;
            break;
    }
    filteredTable.sort(sortingFn);

    return (  
        <div className="units">
            <div className="units-search">
                <Form>
                    <Row>
                        <Col>
                            <InputGroup>
                                <InputGroup.Text>Kereső</InputGroup.Text>
                                <FormControl 
                                    value={searchInputValue}
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                    placeholder="Név, típus, település, FELIR..."
                                />
                            </InputGroup>
                        </Col>

                        <Col md={3}>
                            <InputGroup>
                                <InputGroup.Text>ÁMT?</InputGroup.Text>
                                <Form.Select value={amtFilterValue} onChange={(e) => setAmtFilterValue(e.target.value)}>
                                    <option value="">Mindegy</option>
                                    <option value="true">Igen</option>
                                    <option value="false">Nem</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>

                        <Col md={3}>
                            <InputGroup>
                                <InputGroup.Text>Megszűnt?</InputGroup.Text>
                                <Form.Select value={megszuntFilterValue} onChange={(e) => setMegszuntFilterValue(e.target.value)}>
                                    <option value="">Mindegy</option>
                                    <option value="true">Igen</option>
                                    <option value="false">Nem</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{ 'marginTop': '6px' }}>
                        <Col md={{ span: 3, offset: 6 }}>
                            <InputGroup>
                                <InputGroup.Text>Rendezés</InputGroup.Text>
                                <Form.Select value={sortingValue} onChange={(e) => setSortingValue(e.target.value)}>
                                    <option value="id-desc">ID szerint csökkenő</option>
                                    <option value="id-asc">ID szerint növekvő</option>
                                    <option value="type-asc">Típus szerint ABC</option>
                                    <option value="last-rev-asc">Utolsó ellenőrzés növekvő</option>
                                    <option value="last-rev-desc">Utolsó ellenőrzés csökkenő</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </div>

            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th colSpan="8">
                        { filteredTable.length !== data.egysegek.length
                            ? <span>Találatok: {filteredTable.length} / {data.egysegek.length}</span>
                            : <span>Összes egység: {data.egysegek.length}</span>
                        }
                        </th>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <th>Egység név</th>
                        <th>Település</th>
                        <th>Utolsó ell.</th>
                        <th>Tanúsítvány</th>
                        <th>FELIR</th>
                        <th>Típus</th>
                        <th>ÁMT</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        filteredTable.map(e => (
                            <tr key={e.id} className="unit-page-row">
                                <td>{e.id}</td>
                                <td>
                                    <Link to={'/' + e.id}>
                                        {e.egyseg_nev}
                                    </Link>
                                    <span style={{"float": "right"}}>
                                      { e.megszunt &&
                                        <Badge className="megszuntBadge" bg="danger" pill>Megszűnt</Badge>
                                      }
                                      <OldTaskStatusBadge status={e.feladat_statusz}/>
                                      <SOSBadge sos={e.sos}/>
                                    </span>
                                </td>
                                <td>{e.telepules_nev}</td>
                                <td>{formatDate(e.utolso_ellenorzes)}</td>
                                <td>{e.tanusitvany_szam}</td>
                                <td>{e.felir_szam}</td>
                                <td>{e.egyseg_tipus}</td>
                                <td className="text-center">
                                    { e.amt
                                        ? <i className="bi bi-check-circle-fill text-success"></i>
                                        : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default UnitsPage;