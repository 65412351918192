import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';

import { useMutation } from "@apollo/client";
import { ADD_UNIT_MUTATION, UNITS_QUERY } from "../typeDefs";

function NewUnitPage() {
  const history = useHistory();
  const [addUnit] = useMutation(ADD_UNIT_MUTATION, {
    onCompleted: (data) => {
      toast.success(`Sikeres mentés! ID: ${data.addEgyseg}`);
      history.push(`/${data.addEgyseg}`);
    },
    onError: () => {
      toast.error('Hiba a mentés során!');
    },
    refetchQueries: [{ query: UNITS_QUERY }]
  });

  const validate = (values) => {
    const errors = {};
    if (!values.egyseg_nev || values.egyseg_nev.length < 2 ) {
      errors.egyseg_nev = 'Egység név nincs megadva!';
    }
    return errors;
  };

  const onSubmit = (values) => {
      addUnit({ variables: {
        egyseg_nev: values.egyseg_nev
      } });
  };

  return (
    <div className="card">
      <div>
        <div className="card-header">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Vissza</Link>
              </li>
              <li className="breadcrumb-item">Új egység létrehozása</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-body">
        <Formik initialValues={{ egyseg_nev: '' }} onSubmit={onSubmit} validate={validate}>
          {({ isSubmitting, values, handleReset }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="egyseg_nev">Egység neve</label>
                <Field name="egyseg_nev" type="text" className="form-control" />
                <ErrorMessage name="egyseg_nev" component="div" />
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}
                      style={{"width": "150px", "marginTop": "15px"}}>
                Mentés
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default NewUnitPage;