import { Badge } from "react-bootstrap";

export default function TaskStatusBadge({ status }) {
  switch (status) {
    case 'NEW':
      return <Badge className="megszuntBadge" bg="danger">Új feladat</Badge>;
    case 'IN_PROGRESS':
      return <Badge className="megszuntBadge" bg="warning">Folyamatban</Badge>;
    case 'BLOCKED':
      return <Badge className="megszuntBadge" bg="dark">Várunk..</Badge>;
    case 'REVIEW':
        return <Badge className="megszuntBadge" bg="primary">Ellenőrzés</Badge>;
    case 'DONE':
      return <Badge className="megszuntBadge" bg="success">Kész</Badge>;
    default:
      return null;
  }
}